@layer preIPS {
    body {
        color: var(--textColorPrimary);
    }

    a {
        color: var(--accentColorLink);
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--accentColorLink);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--sans);
        /*margin: 0 0 var(--spacing16);*/
    }

    h1 {
        font: normal normal var(--fontWeightLight) var(--fontSize52) /
            var(--lineHeightTight) var(--sans);
        font-weight: var(--fontWeightLight);
    }

    h2 {
        font-size: var(--fontSize28);
        line-height: 1.2;
        font-weight: var(--fontWeightLight);
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: var(--fontSize18);
        font-weight: var(--fontWeightBold);
    }

    h3 {
        line-height: 1.278;
        letter-spacing: 0.00278em;
        /*text-transform: uppercase;*/
    }

    h4,
    h5,
    h6 {
        line-height: 1.222;
    }

    dt {
        font-size: var(--fontSize18);
        font-style: italic;
        font-weight: var(--fontWeightNormal);
        line-height: var(--lineHeightTight);
    }

    dd {
        margin: 0;
    }

    code {
        font-family: var(--monospace);
        white-space: pre-wrap;
    }
}
