.toaster[class] {
    bottom: var(--spacing16);
    left: 50%;
    right: auto;
    display: flex;
    position: fixed;
    align-items: center;
    transition: 250ms ease-in-out;
    transform: translate3d(-50%, calc(100% + var(--spacing16)), 0);

    &.show,
    &:focus-within {
        transform: translate3d(-50%, 0, 0);
    }
}

/* This isn't used but, I figured I'd leave it in here for the future. */
.toaster.right[class] {
    left: auto;
    right: var(--spacing16);
    transform: translate3d(0, calc(100% + var(--spacing16)), 0);

    &.show,
    &:focus-within {
        transform: translate3d(0, 0, 0);
    }
}
