.loading {
    background: var(--background-color-level-current);
    position: relative;
    width: 100%;

    & .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        min-height: 100px;

        & .outerLoader {
            border: 3px solid var(--borderColorTertiary);
            border-block-start-color: var(--accentColorPrimary);
            border-radius: 50%;
            position: absolute;
            width: 4vw;
            height: 4vw;
            animation: spin 1.5s linear infinite;

            @media (width >= 0em) and (width < 37.5em) {
                width: 25vw;
                height: 25vw;
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes innerSpin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
