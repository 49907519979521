/* TODO: I hate this theme system and our colors.
    We need to designers to pick better colors for each theme. */

:root {
    --input-slider-primary: #d0d3d3;
    --input-slider-secondary: #899090;

    --customGrey1: #e6e6e6;
    --customGrey2: #bbbbbb;
    --customGrey3: #a2aaad;
    --customGrey4: #97999b;
    --customGrey5: #53565a;
    --footerGrey: #f1f1f3;

    --sans: "Ensign:Sans", Arial, "noto sans", sans-serif;
    --serif: "Ensign:Serif", "Georgia", "Times New Roman", serif;
    --monospace: "Courier New", Courier, monospace;

    --fontSize10: 0.625rem;
    --fontSize12: 0.75rem;
    --fontSize22: 1.375rem;
    --fontSize26: 1.625rem;
    --fontSize30: 1.875rem;
    --fontSize36: 2.25rem;
    --fontSize52: 3.25rem;

    --scrollbar: #929292;
    --scrollbar-hover: #707070;
    --scrollbar-background: #f8f8f8;
    --scrollbar-border: #e8e8e8;

    --spacing10: 0.625rem;
    --spacing12: 0.75rem;
    --spacing20: 1.25rem;
    --spacing40: 2.5rem;
    --spacing60: 3.75rem;

    --button-icon-color-disabled: var(--textColorTertiary);
}
