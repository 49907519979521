@import "../../theming";

body {
    padding: 0 !important;
    font-size: var(--fontSize18);
    font-family: var(--sans);
    line-height: var(--lineHeight);
    background-color: var(--background-color-level-current);
}

.app {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > main {
        display: flex;
        flex-direction: column;
    }
}

.panelOpen {
    transform: translateX(calc(var(--panel-offset, 0px) * -1));

    &:dir(rtl) {
        transform: translateX(var(--panel-offset, 0px));
    }
}
