.banner[class] {
    background-color: var(--panelBackground);

    & div {
        color: var(--textColorPrimary);
    }

    & > button {
        /* styles for the close button from theming/components/eden-buttons/eden-buttons.css */
        /* css modules supports composing, but not when deeply nested like this and we don't have a class to latch onto */
        color: var(--textColorPrimary);

        &:active {
            background-color: var(
                --button-icon-background-active,
                var(--grey3)
            );
        }

        &:disabled {
            background: transparent;
            color: var(--button-icon-color-disabled, var(--grey20));

            &:hover {
                background: transparent;
                color: var(--button-icon-color-disabled, var(--grey20));
            }
        }
    }
}

.basic[class] {
    border-block-end-color: var(--accentColorPrimary);

    & > div path {
        fill: var(--accentColorPrimary);
    }
}

@media print {
    .banner[class] {
        display: none;
    }
}
