.login {
    display: block;
    width: 100%;
    margin-block: var(--spacing8);
    text-align: center;
    font-family: var(--sans);

    & a {
        margin-block-start: var(--spacing16);

        &:last-of-type {
            margin-block-end: var(--spacing16);
        }
    }

    & p {
        margin-inline: var(--spacing8);
    }

    & .signInHelpLink {
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    & .outOfService {
        color: var(--accentColorDanger);
    }
}
